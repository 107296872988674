import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiSpacer } from '@elastic/eui';
import NavButtons from 'components/Motion_Detection/INSTAR_Cloud/IFTTT/NavButtons';
import ForumBox from 'components/Motion_Detection/INSTAR_Cloud/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Adding your Camera Credentials to your INSTAR Cloud Account",
  "path": "/Motion_Detection/INSTAR_Cloud/IFTTT/User_Guide/How_to_add_P2P_Credentials/",
  "dateChanged": "2020-10-21",
  "author": "Mike Polinowski",
  "excerpt": "On the IFTTT Platform, we provided a service named INSTAR. The INSTAR Applets provides a way for you to control some settings of your INSTAR Camera or INSTAR Cloud with IFTTT. You can connect INSTAR to Location and Date & Time triggers and receive Notifications and more. For example, you can connected the INSTAR Applets with the IFTTT Location service and to turn off the alarm when you arrive at home. You can also use the INSTAR Applets to build your own automation connecting them to other third-party Applets.",
  "image": "../MD_SearchThumb_Cloud_IFTTT.png",
  "social": "/images/Search/MD_SearchThumb_Cloud_IFTTT.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_MD-INSTAR-Cloud-IFTTT_white.webp",
  "chapter": "Motion Detection"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Adding your Camera Credentials to your INSTAR Cloud Account' dateChanged='2020-10-21' author='Mike Polinowski' tag='INSTAR IP Camera' description='On the IFTTT Platform, we provided a service named INSTAR. The INSTAR Applets provides a way for you to control some settings of your INSTAR Camera or INSTAR Cloud with IFTTT. You can connect INSTAR to Location and Date & Time triggers and receive Notifications and more. For example, you can connected the INSTAR Applets with the IFTTT Location service and to turn off the alarm when you arrive at home. You can also use the INSTAR Applets to build your own automation connecting them to other third-party Applets.' image='/images/Search/MD_SearchThumb_Cloud_IFTTT.png' twitter='/images/Search/MD_SearchThumb_Cloud_IFTTT.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Bewegungserkennung/INSTAR_Cloud/IFTTT/User_Guide/How_to_add_P2P_Credentials/' locationFR='/fr/Motion_Detection/INSTAR_Cloud/IFTTT/User_Guide/How_to_add_P2P_Credentials/' crumbLabel="IFTTT" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h2 {...{
      "id": "how-to-add-p2pid-and-username--password-of-your-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#how-to-add-p2pid-and-username--password-of-your-camera",
        "aria-label": "how to add p2pid and username  password of your camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`How to add P2PID and username & password of your camera?`}</h2>
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#how-to-add-p2pid-and-username--password-of-your-camera"
        }}>{`How to add P2PID and username & password of your camera?`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#added-p2pid-on-instar-cloud-platform"
            }}>{`Added P2PID on INSTAR Cloud platform`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#added-p2pid-on-webuionly-support-fullhd-camera-add-p2pid-here"
            }}>{`Added P2PID on WebUI（Only Support FullHD camera add P2PID here）`}</a></li>
        </ul>
      </li>
    </ul>
    {/* /TOC */}
    <h3 {...{
      "id": "added-p2pid-on-instar-cloud-platform",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#added-p2pid-on-instar-cloud-platform",
        "aria-label": "added p2pid on instar cloud platform permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Added P2PID on INSTAR Cloud platform`}</h3>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Step 01`}</strong>{`: Login INSTAR Cloud, then click the tap "Cameras", next to click "Edit":`}</p>
    </blockquote>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "888px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/18967ede13f138196d025b90df5c21fa/b04e4/p2p-cloud-01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70.43478260869564%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsTAAALEwEAmpwYAAACs0lEQVQ4y32RW0tUURTHD14+hg8+CUEPQWlG36KPEL2UUY6X8FaBURZ0UQxCZwZUfDDIh3rImkITTRnrNKZF5SVHHec4HmfMM86cvc/oL/ZOK9Fa8Gfts9b+/9hrHSM1v8CitclKbJ1sysF2XL4vr7K+mSUtdliykvzIeKTSgsW4TXTFZtPdRgJiG8TOfhnyXRei/yziaQVywId8WY0YqEK+qMJ7Wa2zfFH9+yzU90AlnuqHapEDl3VvT4YYvol8dBQZPIXoLEV0nkDqfPhZqhwoRz4+g/fsHDJ4GuEv030lQwzdwH14BLfz5K7xP/KX4XUcw35WgxXuIzHWQ3IsgFDQjuO6b8jZELmxu+TC7Yi393FH7yHHHuCNt/5DD/AmHuGZQeR7PzkzwHa4XXuU18gBGe/Xgv8OVfcAufNLuUNqKrbENk72j9kYHn7DxYoLVFw4z5XaGlpu3STywcSKr2CvJRBuFjebwYrHSCXX2Uo7ZLbS2vxxMkLl5UtU+Sq5fu0qd263YNTV1WEYBm1tbZSXl+tzIBBgdnaWmZkZhBB4nsf4+DihUIihoSFdV+H3+/X9fWptbaWkpIS+vj4NaW5uJhgMEo/H2djY+A2MRCKYpsnc3ByWZWlgd3e3hhQUFJCXl0d+fj5Gb28vpaWlFBYWUlRURHFxMV1dXdqUSqWQUmrg1NQUIyMjRKNRksnkAaCCKajR2Nh44Nk9PT3Ytk0ikcB1XQ2dnp7m9asQExNhvu2OrFZzYOTBwUF8Ph9NTU00NDRojY6OsrS0xOrqKplMRgMVxJz+yvdli41EXAPfmyY1NdXaU19fr2VwSDiOQywWY21tjbTjID2PxYV5Eh+fY396w+vwZ2KWfZgVQ42kXvG39n6EepkUAqH2KDLwpZ/Nyac8mYgyv2zDTo50emuf9yfG53h9ZNGEvgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/18967ede13f138196d025b90df5c21fa/e4706/p2p-cloud-01.avif 230w", "/en/static/18967ede13f138196d025b90df5c21fa/d1af7/p2p-cloud-01.avif 460w", "/en/static/18967ede13f138196d025b90df5c21fa/dea7a/p2p-cloud-01.avif 888w"],
              "sizes": "(max-width: 888px) 100vw, 888px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/18967ede13f138196d025b90df5c21fa/a0b58/p2p-cloud-01.webp 230w", "/en/static/18967ede13f138196d025b90df5c21fa/bc10c/p2p-cloud-01.webp 460w", "/en/static/18967ede13f138196d025b90df5c21fa/60055/p2p-cloud-01.webp 888w"],
              "sizes": "(max-width: 888px) 100vw, 888px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/18967ede13f138196d025b90df5c21fa/81c8e/p2p-cloud-01.png 230w", "/en/static/18967ede13f138196d025b90df5c21fa/08a84/p2p-cloud-01.png 460w", "/en/static/18967ede13f138196d025b90df5c21fa/b04e4/p2p-cloud-01.png 888w"],
              "sizes": "(max-width: 888px) 100vw, 888px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/18967ede13f138196d025b90df5c21fa/b04e4/p2p-cloud-01.png",
              "alt": "p2p-cloud-01.png",
              "title": "p2p-cloud-01.png",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Step 02`}</strong>{`: After accessing edit page, there is a P2P information module, you can fill your camera's information. After filling your credentials, you need to activate IFTTT, only you do this, then we will use your credential to control your camera via IFTTT. Otherwise, even you connect IFTTT Service INSTAR,  your camera also can't be controlled. If you want to check if your credentials are correct or not, you can click "Test" button to check:`}</p>
    </blockquote>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "887px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4a21ea7877591f2e5eeafb4d2075350f/eac55/p2p-cloud-02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "26.08695652173913%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAYAAABFA8wzAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA9ElEQVQY022Q22qDQBRF87v64i0IipcPEFKsfRXFB39JaMlbEEqMjsZYW8/MLmMwpaUbFufAcBab2ZmmCU3TkCQJmqZBXddoLy2WZQEtBM75vxARZLIsg2VZCMMQnudhtwnjOAZjbOVznlfh8nWXyuO/yHeZPM9hGAaCILgLVVWFoiiIogjD9Qo2DLjNM4gLcCJwwSGEeIi2fROmaQrpeDR0HAfWfo+nwwHnU43mdMLt/A76mECcY5qmlfULiDCO4y9hURTQdf2noTS7rouX5xjt6xGXqsL4VkH0DBxA3zN0XbcKZDu5yymRKcsStm2vDX3fxzdL41TlZ9X/zAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4a21ea7877591f2e5eeafb4d2075350f/e4706/p2p-cloud-02.avif 230w", "/en/static/4a21ea7877591f2e5eeafb4d2075350f/d1af7/p2p-cloud-02.avif 460w", "/en/static/4a21ea7877591f2e5eeafb4d2075350f/abb2d/p2p-cloud-02.avif 887w"],
              "sizes": "(max-width: 887px) 100vw, 887px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4a21ea7877591f2e5eeafb4d2075350f/a0b58/p2p-cloud-02.webp 230w", "/en/static/4a21ea7877591f2e5eeafb4d2075350f/bc10c/p2p-cloud-02.webp 460w", "/en/static/4a21ea7877591f2e5eeafb4d2075350f/1938b/p2p-cloud-02.webp 887w"],
              "sizes": "(max-width: 887px) 100vw, 887px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4a21ea7877591f2e5eeafb4d2075350f/81c8e/p2p-cloud-02.png 230w", "/en/static/4a21ea7877591f2e5eeafb4d2075350f/08a84/p2p-cloud-02.png 460w", "/en/static/4a21ea7877591f2e5eeafb4d2075350f/eac55/p2p-cloud-02.png 887w"],
              "sizes": "(max-width: 887px) 100vw, 887px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4a21ea7877591f2e5eeafb4d2075350f/eac55/p2p-cloud-02.png",
              "alt": "p2p-cloud-02.png",
              "title": "p2p-cloud-02.png",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "added-p2pid-on-webuionly-support-fullhd-camera-add-p2pid-here",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#added-p2pid-on-webuionly-support-fullhd-camera-add-p2pid-here",
        "aria-label": "added p2pid on webuionly support fullhd camera add p2pid here permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Added P2PID on WebUI（Only Support FullHD camera add P2PID here）`}</h3>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Step 01`}</strong>{`: Make sure your p2p be activated:`}</p>
    </blockquote>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "878px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/7970a910d8279e5f48bc91b8368ee631/94829/p2p-webui-01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACOUlEQVQoz2WSbUtTYRiA908yzO15ztkC+9KnaIMw587Zed/Z3OamOVrb7MU1CGU5MZgLqu9pgSAhFipI+COi936JH0W54pxlRT1wcX+7uG7uJ5JIJIjH47+RQjAWk9y484J0e4vJB5ukFl6RbG2QvPua1MImqeZLks0NUq0/6J0tJurPiaiKwjmKlAghiAlBvtGntnZArbdDdWmL2e4O5c4G7tI27soe/spbcr193O4u7vIb8qt7OAtPiQipIFUVRY0TC4RSIXrpIp5uMtvoUZ5rUyzVKVbuUy3VcKr38JprFJpdnPlHmNVFrJkm7u1ldNsnclkKriiScVVhXBGoUjI6Okq1UuHb5w/8+PqJ718CPrK68hhTz1DI2eQcC8vQQmxDpzztYxhZIldllKQa45oquC7HGFckF0ZGqM3X+Petrw+wLJvyTAXX88gXpsn5eTwvh5fzMUyTSLCmVFSElMSEHM5oNBQeHx9zdnbGyckJp6en9Pt9LMuiWCziOA6+74d4nhcSCpXgIKoaIuVQKGIxWo3Gf4WDwQDbtkNhMIPCfL5AzvPwPQ8zEJ7LArH8dWVFCoqlMtu7+7w7eM/h4SFHR0d0Oh1c16VQmMYyDRwjg2PqaI5DxnXQ/y4MZENhDEWJM5fP8qSh8bCaRtc1NE0L1w3KbMvALlRx613c2UUWsxbLukkpawwLww8dCIUYCtUEFWuCZ6003VuTZKfSaJkMuq4P0abQvTJWvYdZbdNOa3RvTlHMaPwERi6ik9LtgNgAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7970a910d8279e5f48bc91b8368ee631/e4706/p2p-webui-01.avif 230w", "/en/static/7970a910d8279e5f48bc91b8368ee631/d1af7/p2p-webui-01.avif 460w", "/en/static/7970a910d8279e5f48bc91b8368ee631/b7a7f/p2p-webui-01.avif 878w"],
              "sizes": "(max-width: 878px) 100vw, 878px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/7970a910d8279e5f48bc91b8368ee631/a0b58/p2p-webui-01.webp 230w", "/en/static/7970a910d8279e5f48bc91b8368ee631/bc10c/p2p-webui-01.webp 460w", "/en/static/7970a910d8279e5f48bc91b8368ee631/6749f/p2p-webui-01.webp 878w"],
              "sizes": "(max-width: 878px) 100vw, 878px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7970a910d8279e5f48bc91b8368ee631/81c8e/p2p-webui-01.png 230w", "/en/static/7970a910d8279e5f48bc91b8368ee631/08a84/p2p-webui-01.png 460w", "/en/static/7970a910d8279e5f48bc91b8368ee631/94829/p2p-webui-01.png 878w"],
              "sizes": "(max-width: 878px) 100vw, 878px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/7970a910d8279e5f48bc91b8368ee631/94829/p2p-webui-01.png",
              "alt": "p2p-webui-01.png",
              "title": "p2p-webui-01.png",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Step 02`}</strong>{`: You need to activate "Allow control from the cloud". After activated it, your P2P information and camera's username&password will be save on your INSTAR Cloud Platform. If you aren't willing to do here, you also can go to INSTAR Cloud platform to finish manually:`}</p>
    </blockquote>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "877px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/d40a4dc6aaefd5c13219b228f0456379/4b446/p2p-webui-02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "57.391304347826086%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAACLElEQVQoz22RTU8TURSGu3MnhAU7CLTSuTNThEhISARKsLad7975aKeltoABEkETA4lfCdUoexMTV/4HE1eC/rfHzB0pwbh4cs7dPPd9cwqLi4tUKhVFtuu6jiYE90pFxNIqcvQD+ek34ccr7NElzfN/+Yl1fkl0cUVy8YuCYRhck8mUUNMoazr3V9ZoH76m//wDOycjkqN3RAdviQ5vEx684cmL9+ycnOdC0zQVuVCohHMzMxjlBaKWS7+b0G1HapeehfRtNVtuk5ZnETh12mmXuNOjcC27EeaVy6UShlYmThIGw106nZQwjJBhRBTF1B7XWV/foFrdYnOzysONKrYb3AizpEKIceX52VlMXUNKSa/Xo9PpEIahemfTcRyFZVnU63UebW/jue6NMBMpoRBqVjTB3PQ0oZT0+3263S5xHI+FrusqGo0GtVpNST3f/0/CTKjrrC4vMz85RdiSDHeHKmW73R6nzPbr5Nmepqn6MD/K+Mp5Ot0wWNZ1SpNTyCAgTTskcXyrsu/7eJ6X47o0LStPmB1AZJXNCkI3xkKzWKR0d4IwiukN9knSvkoRRdlhssoOjt3A9RwszyW2XXZkQuGB0NiqCMWaXlYX1k2DpZkixTsT9COXl/uS40GL+K8ski3SdEg6eEYv3eUoCDhthexLSWHJNFjNqGQ188pC6BgrC8wbs5zuNfk+svl21iCSAYEMiX2bp3tnHJx95XjvFZ/rTb5YPkeOxx/EQ487KYPlfwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d40a4dc6aaefd5c13219b228f0456379/e4706/p2p-webui-02.avif 230w", "/en/static/d40a4dc6aaefd5c13219b228f0456379/d1af7/p2p-webui-02.avif 460w", "/en/static/d40a4dc6aaefd5c13219b228f0456379/0a597/p2p-webui-02.avif 877w"],
              "sizes": "(max-width: 877px) 100vw, 877px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/d40a4dc6aaefd5c13219b228f0456379/a0b58/p2p-webui-02.webp 230w", "/en/static/d40a4dc6aaefd5c13219b228f0456379/bc10c/p2p-webui-02.webp 460w", "/en/static/d40a4dc6aaefd5c13219b228f0456379/d7666/p2p-webui-02.webp 877w"],
              "sizes": "(max-width: 877px) 100vw, 877px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d40a4dc6aaefd5c13219b228f0456379/81c8e/p2p-webui-02.png 230w", "/en/static/d40a4dc6aaefd5c13219b228f0456379/08a84/p2p-webui-02.png 460w", "/en/static/d40a4dc6aaefd5c13219b228f0456379/4b446/p2p-webui-02.png 877w"],
              "sizes": "(max-width: 877px) 100vw, 877px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/d40a4dc6aaefd5c13219b228f0456379/4b446/p2p-webui-02.png",
              "alt": "p2p-webui-02.png",
              "title": "p2p-webui-02.png",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Step 03`}</strong>{`: Then, you need to go to tab SMARTHOME > IFTTT and to allow IFTTT to control your camera:`}</p>
    </blockquote>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "876px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/f4dbe0d8996a0e83e8aca43e65ac780c/1b1d5/p2p-webui-03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.52173913043479%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAACSklEQVQoz22RzU/TcBiAa6I3ozECooiuw0G3rqxACDNCA4IShG10LWvXrlsnetCLF8V4wIN8bcSEbGx4wHhQ/wiJJ/+yx7QDQfHw5P1Ifk/e3/sKiqIgy/If4vE4Q5JELBZjZHIef/cIa/snxsYRxuYxZ/J8kG/8oLr3C/PdV4REIkFAIJMkqSMcGiIqRkkNpzDzOTzHwisWKDsFyq5FpWSHMcALeo6Fa5tknswj/DtdRygh3uknGZdwHJfV1Wf4fhXXLYWUPC/sh3Wp0/O8Msu6fioMpjw7YSwqklJkbNumWq3i+z7lcjnE87w/eaVSoVLxcVyXbDZ7KgxEf325v5/4vQEsywplwcMgnuC6bojjOHiuS7FQIJtZOi8MpYkEo8kkYlc3pmFQdJxw0mKxGEbDNMllM+SyS2QzC9j2Uyprn7ENt3OU/wnVeJxYdw+6rmOaJkbewDAM8oaBoeu4lkvZXcVbKfJiWefVisPK4uL5o5wIZVFk8Ho3uVwOPZ8PxQHB4s1cBv/5OuW3h7z039Cee0R7IUPp8TxCIpAlFWRFIXF8HDmZRLkbIXr5CmMpBbmvj2RXDxOqypSmoWka09okM9oDZh5Oo83Nos3OMjU9jRATI+HypajIQCRCJBJBjEYZ7L3FzYuXuHHtKr3CBXoEgVjfbYZVFVVVGRkbZWx8nPH0BOn7aSbS6bAWDuo7fN/d4lt9i716jZ16nVqtxvZhjc0v23z4tEFt/T1br9f4uFOj0WjQbDZo7jfZb+3TardpHxyENFstfgNDgpn0IbFYVAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f4dbe0d8996a0e83e8aca43e65ac780c/e4706/p2p-webui-03.avif 230w", "/en/static/f4dbe0d8996a0e83e8aca43e65ac780c/d1af7/p2p-webui-03.avif 460w", "/en/static/f4dbe0d8996a0e83e8aca43e65ac780c/5579a/p2p-webui-03.avif 876w"],
              "sizes": "(max-width: 876px) 100vw, 876px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/f4dbe0d8996a0e83e8aca43e65ac780c/a0b58/p2p-webui-03.webp 230w", "/en/static/f4dbe0d8996a0e83e8aca43e65ac780c/bc10c/p2p-webui-03.webp 460w", "/en/static/f4dbe0d8996a0e83e8aca43e65ac780c/21dbd/p2p-webui-03.webp 876w"],
              "sizes": "(max-width: 876px) 100vw, 876px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f4dbe0d8996a0e83e8aca43e65ac780c/81c8e/p2p-webui-03.png 230w", "/en/static/f4dbe0d8996a0e83e8aca43e65ac780c/08a84/p2p-webui-03.png 460w", "/en/static/f4dbe0d8996a0e83e8aca43e65ac780c/1b1d5/p2p-webui-03.png 876w"],
              "sizes": "(max-width: 876px) 100vw, 876px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/f4dbe0d8996a0e83e8aca43e65ac780c/1b1d5/p2p-webui-03.png",
              "alt": "p2p-webui-02.png",
              "title": "p2p-webui-02.png",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      